import styled from "styled-components";

import { ComboboxInputIcon } from "components/ui/Combobox/ComboboxInput/ComboboxInputIcon";
import { ComboboxInputIcons } from "components/ui/Combobox/ComboboxInput/ComboboxInputIcons";
import { ComboboxOptions, ItemValue } from "components/ui/Combobox/combobox.types";
import { Icon } from "components/ui-deprecated";

const ComboboxInputContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: center;

    background-color: ${({ theme }) => theme.color.white};
    border-radius: 0.25rem;
    box-shadow: 0 2px 5px #dedbd666;
    outline: transparent;
`;

const ComboboxInputStyled = styled.input`
    width: 100%;
    padding: 0.5rem 1rem;

    display: inline-block;

    background: none;
    border: none;
    outline: none;

    text-overflow: ellipsis;
`;

type Props<T extends ItemValue> = {
    value: T;
    onChange: (value: T) => void;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    isMenuOpen: boolean;
    onMenuToggleClick: () => void;
    onReset: () => void;
    options?: ComboboxOptions;
};

const ComboboxInput = <T extends ItemValue>(props: Props<T>) => {
    const { value, options } = props;
    const placeholder = options?.placeholder ?? "Select...";
    const hideDeselect = options?.hideDeselect ?? false;

    return (
        // Classname has to be here for now, so styles can be overriden in selects in widgets
        <ComboboxInputContainer className={"select-input-wrapper"}>
            <ComboboxInputStyled
                type={"text"}
                value={value ?? undefined}
                placeholder={placeholder}
                onChange={(e) => props.onChange(e.target.value as T)}
                onBlur={props.onBlur}
                size={1}
            />
            <ComboboxInputIcons>
                {!hideDeselect && value !== "" && (
                    <ComboboxInputIcon onClick={props.onReset}>
                        <Icon icon={`times`} />
                    </ComboboxInputIcon>
                )}
                <ComboboxInputIcon onClick={props.onMenuToggleClick}>
                    <Icon icon={`angle-${props.isMenuOpen ? "up" : "down"}`} />
                </ComboboxInputIcon>
            </ComboboxInputIcons>
        </ComboboxInputContainer>
    );
};

export { ComboboxInput };

import styled from "styled-components";

import { Item, ItemValue } from "components/ui/Combobox/combobox.types";

const ComboboxMenuItemStyled = styled.div`
    overflow: hidden;

    width: 100%;

    padding: 0.5rem 1rem;

    font-size: 14px;

    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;

    transition: all 0.1s ease-in-out;

    &:hover {
        background-color: #dedbd666;
    }

    &.active {
        background-color: #fdf5f5;
    }
`;

type Props<T extends ItemValue> = {
    item: Item<T>;
    onItemSelect: (item: Item<T>) => void;
};

const ComboboxMenuItem = <T extends ItemValue>(props: Props<T>) => {
    const { item, onItemSelect } = props;
    const { label } = item;

    return (
        <ComboboxMenuItemStyled onClick={() => onItemSelect(item)} title={label}>
            {label}
        </ComboboxMenuItemStyled>
    );
};

export { ComboboxMenuItem };
